/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
// import { css } from 'aphrodite/no-important';
import styles from './styles';

class SuggestionItem extends Component {
  render() {
    const { type, index, weak_type_chinese, isSelected, onClick, styles, suggestion_chinese, appreciation_chinese, correction } = this.props;
    const bgColor = type === 'good' ? '#52f760' : '#f7b252';

    return (
      <View
        key={index}
        id={`suggestion-${type}_${index}`}
        className={isSelected === `${type}_${index}` ? styles.resultItemSelected : styles.resultItem}
        onClick={() => onClick(`${type}_${index}`)}
      >
        <View className={styles.typeBox}>
          <View 
            className={styles.text}
            style={{ backgroundColor: bgColor }}
          >
            {type === 'good' ? '表现优异' : (weak_type_chinese || '其他错误')}
          </View>
        </View>
        <View className={styles.title}>
          <View className={styles.titleContent}>
            批改建议：{type === 'good' ? '表现优异' : correction}
          </View>
        </View>
        <View className={styles.title}>
          <View className={styles.titleContent}>
            批改解释：{type === 'good' ? appreciation_chinese : suggestion_chinese || 'None'}
          </View>
        </View>
      </View>
    );
  }
}

class SuggestionList extends Component {
  scrollToItem = (id) => {
    if (!id) return;
    
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSelected !== this.props.isSelected && this.props.isSelected) {
      this.scrollToItem(`suggestion-${this.props.isSelected}`);
    }
  }

  render() {
    const { list, isSelected, onClickSentence } = this.props;

    return (
      <View className={styles.result}>
        <h3>批改建议 <View className={styles.num}>{ list.length }</View></h3>
        <View className={styles.scrollContainer}>
          {Object.entries(list).map(([key, item]) => (
            <SuggestionItem
              key={key}
              id={`suggestion-${item.type}_${key}`}
              index={key}
              isSelected={isSelected}
              onClick={() => onClickSentence(item.type, key)}
              styles={styles}
              {...item}
            />
          ))}
        </View>
      </View>
    );
  }
}

export default SuggestionList;
