/* eslint-disable */
import React from "react"
import { View } from '@zhike/ti-ui';
import { StyleSheet } from 'aphrodite';

const Cylindricity = props => {

  const { title, height = 0, name, max, index } = props

  // 反转百分比
  const transformPercentage = percentageString => {
    const percentageValue = parseFloat(percentageString.replace('%', ''))
    const inversePercentage = 1 - percentageValue / 100
    return (inversePercentage * 100).toFixed(2) + '%'
  }

  // max和index用于判断2条、3条数据展示额外样式
  const marginLeft = `${max === 2 && index === 0 ? '6rem' : ''}${max === 3 && index === 0 ? '4rem' : ''}`
  const marginRight = `${max === 2 && index === 1 ? '6rem' : ''}${max === 3 && index === 2 ? '4rem' : ''}`

  const circle = {
    '0%': {
      clipPath: 'inset(100% 0% 0% 0%)'
    },
    '100%': {
      clipPath: `inset(${transformPercentage(height)} 0% 0% 0%)`

    },
  };

  const styles = StyleSheet.create({
    cylindricity: {
      flexGrow: '1',
      fontSize: '1rem',
      height: '14rem',
      marginLeft: '0.2rem',
      textAlign: 'center',
      marginLeft: marginLeft,
      marginRight: marginRight
    },
    title: {
      display: 'inline',
      height: '1.5rem',
      fontSize: '0.8rem',
      fontWeight: '900'
    },
    rollBar: {
      height: 'calc(100% - 4rem)',
      borderRadius: '0.35rem',
      width: '1rem',
      margin: '0 auto',
      border: '2px solid black',
      position: 'relative',
      boxSizing: 'border-box',
    },
    progress: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(#FA6CB4, #FA6CB4A1)',
      transition: 'all 1s',
      animationName: [circle],
      animationDuration: `${0.8 + (index) * 0.2}s`,
      animationTimingFunction: 'ease-in',
      animationFillMode: 'forwards',
    },
    name: {
      fontWeight: 800,
      marginTop: '0.5rem',
      display: 'inline',
      height: '2rem',
      fontSize: '0.7rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });


  return <View className={styles.cylindricity}>
    <View className={styles.title}>{title}</View>
    <View className={styles.rollBar}>
      <View className={styles.progress} />
    </View>
    <View className={styles.name}>{name}</View>
  </View>
}
export default Cylindricity
