import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  notification: {
    position: 'fixed',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '20px 40px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    animationName: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    animationDuration: '0.5s',
  },
  message: {
    fontSize: '18px',
    color: '#333',
  },
  subtext: {
    marginTop: '10px',
    fontSize: '14px',
    color: '#aaa',
  },
});
