import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { history } from 'routers';
import { formatDuration, getLogoProp } from 'utils';
import { View, Image, Button } from '@zhike/ti-ui';
import { Modal, AudioPlayer, Recorder } from '@zhike/ti-component';
import Footer from 'components/footer';
import { get } from 'lodash';
import { createPromise } from 'utils/action';
import styles from './styles';

// 初始状态
const initialOptions = {
  exerciseId: undefined,
  stepId: undefined,
  title: '',
  showButtons: [], // 'correct', 'volume', 'back', 'next'
  unavailableButtons: [],
  isShowTime: false,
  onClickNext: undefined,
  onClickContinue: undefined,
  onClickBack: undefined,
  onClickGoToQuestion: undefined,
  onClickReturn: undefined,
  audioMaterials: [],
};

// 页面头部
export default class Header extends Component {
  static instance;

  // 设置头部
  static config(options = {}) {
    if (this.instance) {
      this.instance.setState({
        options: Object.assign(
          {},
          initialOptions,
          options.inherit ? this.instance.state.options : {},
          options,
        ),
      });

      const { name } = this.instance.props;
      global.document.title = `${name} - 智课`;
    } else {
      setTimeout(() => {
        this.config(options);
      }, 100);
    }
  }

  // 上一步
  static back() {
    this.instance.back();
  }

  // 下一步
  static next() {
    this.instance.next();
  }

  // 上传失败
  static uploadFailed() {
    this.instance.handleTimeExpired();
  }

  // 设置正向计时
  static setTimer(options) {
    this.instance.setTimer(options);
  }

  // 暂停计时
  static pauseTimer() {
    this.instance.setState({
      isPauseTimer: true,
    });
  }

  // 清空倒计时
  static cleanTimer() {
    if (this.instance) {
      this.instance.cleanTimer();
    }
  }

  // 暂停音频
  static pauseAudio() {
    if (this.instance) {
      this.instance.pauseAudio();
    }
  }

  // 获取计时
  static getTime() {
    return get(this, 'instance.props.timer.time') || 0;
  }

  // 开启计时
  static startTimer() {
    const isPauseTimer = get(this.instance, 'state.isPauseTimer');
    if (isPauseTimer) {
      this.instance.setState({
        isPauseTimer: false,
      });
    }
  }
  static defaultProps = {
    newSetRecord: {},
  }
  // 参数
  static propTypes = {
    params: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    stepList: PropTypes.array.isRequired,
    timer: PropTypes.object.isRequired,
    setTimer: PropTypes.func.isRequired,
    getUploadSignature: PropTypes.func.isRequired,
    postCorrectionImage: PropTypes.func.isRequired,
    postCorrection: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    newSetRecord: PropTypes.object,
    logo: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = Object.assign({
      options: initialOptions,
      isShowVolume: false,
      volume: 1,
      isShowDetailTime: false,
      isPlaying: true, // 是否播放音频
    });
    this.saveDurationTime = 0;
    this.setIntervalTime = null;
  }

  // 模块即将加载
  componentWillMount() {
  }

  // 模块加载
  componentDidMount() {
    this.resetTimer(this.props);
    this.setIntervalTime = setInterval(() => this.countTime(), 1000);
  }

  // 保存时间
  saveDuration({ type, questionId, questionMaterialId }) {
    if (this.saveDurationTime === 5) {
      const { postDuration, timer } = this.props;
      const { exerciseId } = this.props.params;
      const time = timer && timer.time || 0;
      const data = { exerciseId, duration: parseInt(time / 1000, 10) }
      if (type === 'SpeakingQuestion' || type === 'WritingQuestion') {
        data.type = type;
        data.questionId = questionId;
        data.questionMaterialId = questionMaterialId;
      }
      createPromise(postDuration, data);
      this.saveDurationTime = 0;
    }

    this.saveDurationTime += 1;
  }

  // 更新
  componentWillReceiveProps(nextProps) {
    if (this.props.step.id !== nextProps.step.id) {
      this.setState({ isPlaying: true });
      this.resetTimer(nextProps);
    }
  }

  // 模块销毁
  componentWillUnmount() {
    if (this.setIntervalTime) {
      clearInterval(this.setIntervalTime);
    }
    this.stopPlayAudio();
  }

  // 模考写作提交机器批改
  async writingMachinePigai() {
    const { step, postMachinePigai, record } = this.props;
    if (step.subjectId !== 7 && step.subjectId !== 8) return false;
    const questions = _.map(_.values(record), x => _.get(x, ['newStep', step.id]));
    const answerIds = _.flatMap(questions, x => _.map(_.values(x), 'answerId'));
    for (const answerId of answerIds) {
      if (answerId) {
        await createPromise(postMachinePigai, {
          subject: step.subjectId === 7 ? 'writing' : 'speaking',
          answerId,
        });
      }
    }
  }

  // 获取计时
  getTime() {
    return this.props.timer ? (this.props.timer.time || 0) : 0;
  }

  // 设置计时器
  setTimer(value) {
    const { timer, setTimer } = this.props;
    const { mode, exerciseId } = this.props.params;
    setTimer({
      mode,
      id: exerciseId,
      value: Object.assign({}, timer, value),
    });
  }

  // 清空计时器
  cleanTimer() {
    const { mode, exerciseId } = this.props.params;
    this.setTimer({
      mode,
      id: exerciseId,
      value: {},
    });
  }

  // 重设计时器
  resetTimer(nextProps) {
    const { timer, step } = nextProps;
    if (timer.mode === 'mock') {
      if (step.type === 'ListeningQuestion' && timer.subject !== 'ListeningQuestion') {
        this.setTimer({
          subject: 'ListeningQuestion',
          time: 32 * 60 * 1000,
          isCountDown: true,
          isEnd: false,
        });
      }
      if (step.type === 'ReadingQuestion' && timer.subject !== 'ReadingQuestion') {
        this.setTimer({
          subject: 'ReadingQuestion',
          time: 60 * 60 * 1000,
          isCountDown: true,
          isEnd: false,
        });
      }
      if (step.type === 'WritingQuestion' && timer.subject !== 'WritingQuestion') {
        this.setTimer({
          subject: 'WritingQuestion',
          time: 60 * 60 * 1000,
          isCountDown: true,
          isEnd: false,
        });
      }
      if (step.type === 'SpeakingQuestion' && timer.subject !== 'SpeakingQuestion') {
        let time = 14 * 60 * 1000
        if (this.checkInsideWithoutTimer()) {
          time = 30 * 60 * 1000
        }

        this.setTimer({
          subject: 'SpeakingQuestion',
          time,
          isCountDown: true,
          isEnd: false,
        });
      }
    }

    if (timer.mode !== 'mock') {
      // 监测是否开始听力题倒计时
      if (
        timer.subject !== 'ListeningQuestion' &&
        step.type === 'ListeningQuestion'
      ) {
        this.setTimer({
          subject: 'ListeningQuestion',
          time: 0,
          isEnd: false,
        });
      }
      // 监测是否开始阅读题倒计时
      if (
        timer.subject !== 'ReadingQuestion' &&
        step.type === 'ReadingQuestion'
      ) {
        this.setTimer({
          subject: 'ReadingQuestion',
          time: 0,
          isEnd: false,
        });
      }

      // 监测是否开始口语题倒计时
      if (
        timer.subject !== 'SpeakingQuestion' &&
        step.type === 'SpeakingQuestion'
      ) {
        this.setTimer({
          subject: 'SpeakingQuestion',
          time: 0,
          isEnd: false,
        });
      }

      // 监测是否开始写作题倒计时
      if (
        timer.subject !== 'WritingQuestion' &&
        step.type === 'WritingQuestion'
      ) {
        this.setTimer({
          subject: 'WritingQuestion',
          time: 0,
          isEnd: false,
        });
      }
    }
  }

  // 设置正向计时
  countTime() {
    const { timer, stepList, step: currentStep } = this.props;
    const { mode } = this.props.params;
    if (mode === 'mock' && timer.isEnd) {
      const stepInfo = stepList.find(ele => ele.id === currentStep.id);
      if (stepInfo.mockPractices) {
        const step = stepList.find(ele => ele.id === (currentStep.id + 1));
        this.handleTimeExpired(step);
      }
    }

    if (!timer.subject || timer.isEnd) {
      return;
    }

    // 每5秒保存一次时间
    const type = get(currentStep, 'type')
    const params = {}
    if (type === 'SpeakingQuestion' || type === 'WritingQuestion') {
      params.type = type
      params.questionId = get(currentStep, ['questionMaterialIds', 0, 'questionId'])
      params.questionMaterialId = get(currentStep, ['questionMaterialIds', 0, 'questionMaterialId'])
    }
    this.saveDuration(params);

    let cntTime;
    if (timer.isCountDown) {
      cntTime = Math.max(0, timer.time - 1000);
    } else {
      cntTime = timer.time + 1000;
    }
    const cntIsEnd = cntTime === 0;
    this.setTimer({
      time: cntTime,
      isEnd: cntIsEnd,
    });
  }

  checkInsideWithoutTimer() {
    try {
      // 判断需要倒计时停止的站点
      // 昆山康桥，最开始要停止计时器，现在要延长口语时间
      const search = global.location.search;
      const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
      const organizationId = decodeURIComponent(search.match(new RegExp('[?#&]organizationId=([^?#&]+)', 'i')) ? RegExp.$1 : '');
      if (source === 'smartInside' && organizationId === 145) { // 目前只有昆山康桥
        return true
      }
    } catch (e) {
      console.log(e);
    }
    return false
  }

  // 上一步
  back() {
    const { step } = this.props;
    const { mode, exerciseId, practiceId } = this.props.params;
    const search = global.location.search; // eslint-disable-line
    history.push(`/${mode}/${practiceId}/${exerciseId}/${step.id - 1}${search}`);
  }

  // 下一步
  next = () => {
    const { step } = this.props;
    const { mode, exerciseId, practiceId } = this.props.params;
    const search = global.location.search; // eslint-disable-line
    history.push(`/${mode}/${practiceId}/${exerciseId}/${step.id + 1}${search}`);
  }

  // 处理倒计时结束
  handleTimeExpired(currentStep) {
    const { stepList } = this.props;
    const { mode, exerciseId, practiceId } = this.props.params;
    const search = global.location.search;
    const step = stepList[stepList.length - 1];
    const stepId = currentStep ? currentStep.id : step.id;
    history.push(`/${mode}/${practiceId}/${exerciseId}/${stepId}/${search}`);
  }

  // 隐藏显示音量
  toggleVolume() {
    const { isShowVolume } = this.state;

    this.setState({
      isShowVolume: !isShowVolume,
    });
  }

  // 调整音量
  adjustVolume(status, event) {
    if (status === 'start') {
      global.window.onmouseup = () => this.adjustVolume('end');
      global.window.onmousemove = event => this.adjustVolume('adjust', event);
    }

    if (status === 'end') {
      global.window.onmouseup = undefined;
      global.window.onmousemove = undefined;
    }

    if (status === 'adjust') {
      const processBar =
              /* eslint-disable */
              ReactDOM.findDOMNode(this.volumeProcessBar)
                .getBoundingClientRect();
      /* eslint-enable */
      const offset = event.x - (processBar.x || processBar.left);
      const volume = Math.max(Math.min(offset / processBar.width, 1), 0);

      this.setState({
        volume,
      });

      AudioPlayer.setVolume(volume);
    }
  }

  handleChangeVolume = (status, event) => {
    if (status === 'adjust') {
      event.persist()
      const clientX = get(event, 'touches[0].clientX')
      const processBar =
              /* eslint-disable */
              ReactDOM.findDOMNode(this.volumeProcessBar)
                .getBoundingClientRect();
      /* eslint-enable */
      const offset = clientX - (processBar.x || processBar.left);
      const volume = Math.max(Math.min(offset / processBar.width, 1), 0);

      this.setState({
        volume,
      });

      AudioPlayer.setVolume(volume);
    }
  }

  // 时间显示与隐藏的切换
  handleEnter() {
    this.setState({
      isShowDetailTime: true,
    });
  }

  handleOut() {
    this.setState({
      isShowDetailTime: false,
    });
  }

  // 继续播放
  resumeAudio() {
    AudioPlayer.resume();
    this.setState({
      isPlaying: true,
    });
  }

  // 暂停音频
  pauseAudio() {
    AudioPlayer.pause();
    this.setState({
      isPlaying: false,
    });
  }

  // 清除播放音频
  stopPlayAudio() {
    AudioPlayer.unload();
    this.setState({
      isPlaying: false,
    });
  }

  // 音频暂停与播放切换
  togglePlay() {
    const { isPlaying } = this.state;
    if (!isPlaying) {
      this.resumeAudio();
    } else {
      this.pauseAudio();
    }
    this.setState({
      isPlaying: !isPlaying,
    });
  }

  onShow = () => {
    AudioPlayer.pause();
    this.setState({
      isPlaying: false,
    });
  };
  onHide = () => {
    AudioPlayer.resume();
    this.setState({
      isPlaying: true,
    });
  };

  // 渲染
  render() {
    const { timer, getUploadSignature, params, postCorrectionImage, postCorrection, step, name, logo } = this.props;
    const { mode } = params;
    const { showButtons, unavailableButtons, onClickContinue, onClickNext, onClickBack, isShowTime } = this.state.options;
    const { volume, isShowVolume, isShowDetailTime, isPlaying } = this.state;
    const logoProp = getLogoProp({ image: logo.data.image, styles });
    const isTouchDevice = 'ontouchstart' in window;
    return (
      <View className={[styles.container, mode === 'mock' && styles.mockContainer]}>
        <View className={styles.content}>
          <View className={styles.title}>
            <Image {...logoProp} />
            <View className={styles.titleSplit} />
            <View className={styles.titleText}>
              {name}
            </View>
          </View>
          <View className={styles.timeButtons}>
            {
              isShowTime &&
              <View
                onMouseLeave={() => this.handleOut()}
                onMouseEnter={() => this.handleEnter()}
                className={styles.buttonTime}
              >
                {
                  isShowDetailTime &&
                  <View>
                    {formatDuration(timer.time || 0, true)}
                  </View>
                }
                {
                  !isShowDetailTime &&
                  <View>
                    {(timer.time || 0) / 60000 < 1 ? 0 : Math.floor(timer.time / 60000)} minute(s)
                  </View>
                }
              </View>
            }
          </View>
          <View className={[styles.normalButtons, mode === 'mock' && styles.mockNormalButtons]}>
            {
              showButtons.indexOf('correct') !== -1 &&
              <Button
                className={styles.button}
                leftIcon={require('./assets/correct.png')}
                isAvailable={unavailableButtons.indexOf('correct') === -1}
                text="我要纠错"
                onClick={() => Modal.show('ModalCorrect', {
                  title: '我要纠错',
                  width: 700,
                  getUploadSignature,
                  postCorrectionImage,
                  postCorrection,
                  step,
                  option: {
                    version: '1.0.0',
                    source: 'ti-ielts',
                  },
                }, this.onShow, this.onHide)}
              />
            }

            {
              showButtons.indexOf('pause') !== -1 &&
              <Button
                className={styles.button}
                leftIcon={isPlaying === true ? require('./assets/pause.png') : require('./assets/play.png')}
                isAvailable={unavailableButtons.indexOf('pause') === -1}
                text={isPlaying === true ? 'Pause' : 'Play'}
                onClick={() => { this.togglePlay(); }}
              />
            }

            {
              showButtons.indexOf('volume') !== -1 &&
              <View className={styles.volumeWrapper}>
                <Button
                  className={styles.button}
                  leftIcon={require('./assets/volume.png')}
                  isAvailable={unavailableButtons.indexOf('volume') === -1}
                  text="Volume"
                  onClick={() => this.toggleVolume()}
                />
                {
                  isShowVolume &&
                  <View
                    className={styles.volume}
                  >
                    <View
                      ref={
                        volumeProcessBar => {
                          this.volumeProcessBar = volumeProcessBar;
                        }
                      }
                      className={styles.volumeProcessBarWrapper}
                    >
                      <View
                        className={styles.volumeProcessBar}
                        style={{ width: volume * 66 }}
                      />
                      {isTouchDevice ?
                        <View
                          className={styles.volumeProcessCircle}
                          style={{ left: volume * 66 - 5 }}
                          onTouchStart={(event) => this.handleChangeVolume('start', event)}
                          onTouchMove={(event) => this.handleChangeVolume('adjust', event)}
                        /> :
                        <View
                          className={styles.volumeProcessCircle}
                          style={{ left: volume * 66 - 5 }}
                          onMouseDown={() => this.adjustVolume('start')}
                        />
                      }
                    </View>
                    <View className={styles.volumeTriangle} />
                  </View>
                }
                {
                  isShowVolume &&
                  <View
                    className={styles.volumeBodyWrapper}
                    onClick={() => this.toggleVolume()}
                  />
                }
              </View>
            }
            {
              showButtons.indexOf('back') !== -1 &&
              <Button
                className={styles.button}
                leftIcon={require('./assets/back.png')}
                isAvailable={unavailableButtons.indexOf('back') === -1}
                text="上一题"
                onClick={async () => {
                  if (onClickBack) {
                    await onClickBack();
                  } else {
                    this.back();
                  }
                }}
              />
            }

            {
              showButtons.indexOf('next') !== -1 &&
              <Button
                className={styles.button}
                rightIcon={require('./assets/next.png')}
                isAvailable={unavailableButtons.indexOf('next') === -1}
                text="Next"
                onClick={async () => {
                  if (onClickNext) {
                    await onClickNext();
                  } else {
                    this.next();
                  }
                }}
              />
            }
            {
              showButtons.indexOf('questionNext') !== -1 &&
              <Button
                className={styles.button}
                rightIcon={require('./assets/next.png')}
                isAvailable={unavailableButtons.indexOf('questionNext') === -1}
                text="Next"
                onClick={async () => {
                  Footer.handleNext();
                }}
              />
            }
            {
              showButtons.indexOf('submit') !== -1 &&
              <Button
                className={styles.button}
                leftIcon={require('./assets/ok@2x.png')}
                isAvailable={unavailableButtons.indexOf('submit') === -1}
                text="Submit"
                onClick={async () => {
                  await this.writingMachinePigai();
                  if (mode !== 'mock') {
                    AudioPlayer.unload();
                  }
                  if (onClickNext) {
                    await onClickNext();
                  } else {
                    this.next();
                  }
                }}
              />
            }

            {
              showButtons.indexOf('continue') !== -1 &&
              <Button
                className={[styles.button, mode === 'mock' && styles.mockButton]}
                rightIcon={mode !== 'mock' && require('./assets/continue.png')}
                isAvailable={unavailableButtons.indexOf('continue') === -1}
                textClassName={mode === 'mock' && styles.mockText}
                text={mode === 'mock' ? step.isPreview ? 'NEXT' : 'CONTINUE' : 'Continue'}
                onClick={async () => {
                  const canGoNext = onClickContinue ? onClickContinue() : true;
                  await this.writingMachinePigai();
                  if (canGoNext) {
                    this.next();
                  }
                }}
              />
            }
          </View>
        </View>
        <Modal ref={modal => { Modal.instance = modal; }} />
        <AudioPlayer ref={audioPlayer => { AudioPlayer.instance = audioPlayer; }} />
        <Recorder ref={recorder => { Recorder.instance = recorder; }} />
      </View>
    );
  }
}
