/* eslint-disable */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';


class RadarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radarData: [],
    };
  }
  renderRadarData(props) {
    if (this.state.radarData.length === 0 && props.data[0]) {
      const max = props.max ? props.max : 1;
      this.setState({
        radarData: new Array(props.data.length).fill(0)
      });
      for (let i = 1; i <= 4; i++) {
        setTimeout(() => {
          this.setState({
            radarData: Array.from({ length: props.data.length }, () => Math.random() * max)
          });
        }, i * 1000);
      }
      setTimeout(() => {
        this.setState({
          radarData: props.data
        });
      }, 5000);
    }
  }
  componentDidMount() {
    this.renderRadarData(this.props)
  }
  // 老板要的幽默特效
  componentWillReceiveProps(nextProps) {
    this.renderRadarData(nextProps)
  }

  render() {
    return <ReactEcharts
      option={{
        backgroundColor: '',
        normal: {
          top: 0,
          left: 0,
          width: 330,
          height: 300,
          zIndex: 6,
          backgroundColor: '#0A2E5D'
        },
        color: ['rgba(245, 166, 35, 1)', 'rgba(19, 173, 255, 1)'],
        title: {
          show: true,
          left: '40%',
          top: '1%',
          textStyle: {
            fontSize: 18,
            color: '#fff',
            fontStyle: 'normal',
            fontWeight: 'normal'
          }
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },
        legend: {
          show: true,
          icon: 'circle',
          left: '35%',
          top: '90%',
          orient: 'horizontal',
          textStyle: {
            fontSize: 14,
            color: '#fff'
          }
        },
        radar: {
          center: ['50%', '50%'],
          radius: '70%',
          startAngle: 90,
          splitNumber: 4,
          shape: 'circle',
          splitArea: {
            areaStyle: {
              color: ['transparent']
            }
          },
          axisLabel: {
            show: false,
            fontSize: 18,
            color: '#fff',
            fontStyle: 'normal',
            fontWeight: 'normal'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#0A2E5D'//
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#0A2E5D'//
            }
          },
          indicator: this.props.indicator
        },
        series: [{
          name: '评分点',
          type: 'radar',
          symbol: 'circle',
          symbolSize: 10,
          areaStyle: {
            normal: {
              color: '#3EB489'
            }
          },
          animationDuration: 1000,
          animationDurationUpdate: 1000,
          animationEasing: 'linear',
          animationEasingUpdate: 'linear',
          itemStyle: {
            color: 'rgba(19, 173, 255, 0)',
            borderColor: 'rgba(19, 173, 255, 0)',
            borderWidth: 0,
          },
          lineStyle: {
            normal: {
              // "type": "dashed",
              color: 'rgba(19, 173, 255, 1)',
              width: 2
            }
          },
          data: [this.state.radarData],
        }]
      }}
      style={this.props.styles}
    />;
  }
}


export default RadarModal;
