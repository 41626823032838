/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import styles from './styles';

export default class TextSegment extends Component {

  render() {
    const { segments, isSelected, onClickSentence } = this.props;

    return (
      <View className={styles.answerTextContent}>
        <View className={styles.textTitle}>作文</View>
        <View className={styles.textContent}>
          <div>
            {segments.map((segment, idx) => {
              if (segment.type === 'text') {
                return <span key={idx}>{segment.content}</span>;
              }

              const isExcellent = segment.type === 'good';
              const segmentType = isExcellent ? 'good' : 'bad';
              const borderColor = isExcellent ? '#52f760' : '#f7b252';
              const bgColor = isExcellent ? 'rgba(82, 247, 96, 0.1)' : 'rgba(247, 178, 82, 0.1)';

              return (
                <span
                  key={idx}
                  id={`sentence-${segmentType}-${segment.index}`}
                  onClick={() => onClickSentence(`${segmentType}_${segment.index}`)}
                  style={{
                    color: '#0e101a',
                    borderBottom: `2px solid ${borderColor}`,
                    backgroundColor: isSelected === `${segmentType}_${segment.index}` ? bgColor : 'transparent',
                    cursor: 'pointer',
                    padding: '2px 4px',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {segment.content}
                </span>
              );
            })}
          </div>
        </View>
      </View>
    );
  }
}
