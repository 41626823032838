import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    margin: '0 auto',
    marginTop: 48,
    width: 1200,
    alignSelf: 'center',
    color: '#505050',
  },

  tabs: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },

  tab: {
    flex: 1,
    padding: '23px 48px',
    borderRadius: '6px',
    marginLeft: '30px',
    border: 'solid 1px #d0dce7',
    ':first-child': {
      marginLeft: 0,
    },
  },

  tabSelect: {
    boxShadow: '0 5px 10px 0 rgba(53, 61, 72, 0.1)',
    border: 'solid 1px #3399ff',
  },

  title: {
    // lineHeight: '24px',
    fontSize: '18px',
    height: '3em',
    paddingBottom: '.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#282828',
    borderBottom: '1px solid #d0dce7',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  circleProgressWrapper: {
    margin: '0 auto',
    marginTop: 25,
    width: 180,
    height: 180,
    position: 'relative',
  },

  circle1: {
    padding: 30,
    width: 180,
    height: 180,
    borderRadius: '50%',
    backgroundColor: '#dfeffe',
  },

  circle2: {
    width: 120,
    height: 120,
    lineHeight: '120px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    textAlign: 'center',
  },

  wrapper: {
    position: 'absolute',
    top: 0,
    width: 90,
    height: 180,
    overflow: 'hidden',
  },

  right: {
    right: 0,
  },

  left: {
    left: 0,
  },

  circleProgress: {
    width: 180,
    height: 180,
    border: '30px solid transparent',
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    // boxSizing: 'border-box',
  },

  rightcircle: {
    borderTop: '30px solid #3399ff',
    borderRight: '30px solid #3399ff',
    right: 0,
    // transform: 'rotate(225deg)',
  },

  leftcircle: {
    borderBottom: '30px solid #3399ff',
    borderLeft: '30px solid #3399ff',
    left: 0,
    // transform: 'rotate(225deg)',
  },

  content: {
    marginTop: '24px',

    h4: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#01101a',
    },
  },

  comment: {
    fontSize: '14px',
  },

  chinese_comment: {
    fontSize: '16px',
  },

  subTitle: {
    marginTop: 24,
    paddingLeft: 12,
    width: '100%',
    height: 48,
    lineHeight: '48px',
    fontSize: 14,
    color: '#fd9827',
    backgroundColor: 'rgba(253, 152, 39, 0.2)',
    border: 'solid 1px #fd9827',
  },

  wordBox: {
    marginTop: 24,
  },

  wordTitle: {
    lineHeight: '32px',
    color: '#3399ff',
  },

  wordContent: {
    lineHeight: '32px',
    color: '#787878',
  },

  videoBox: {
    marginTop: 12,
    padding: '12px 12px 0 12px',
    border: 'solid 1px #d0dce7',
  },

  video: {
    flex: 1,
    flexDirection: 'row',
    borderBottom: '1px solid #d0dce7',
    ':last-child': {
      borderBottom: 'none',
    },
  },

  videoTr: {
    borderBottom: 'none',
  },

  videoTh: {
    marginLeft: 12,
    width: 384,
    height: 36,
    lineHeight: '36px',
    fontSize: 14,
    color: '#a0a0a0',
    backgroundColor: '#f3f6fb',
    ':first-child': {
      marginLeft: 0,
    },
  },

  videoItem: {
    width: 400,
    height: 74,
    lineHeight: '74px',
    textAlign: 'center',
  },

  color3399ff: {
    color: '#3399ff',
  },

  videoImage: {
    margin: '0 auto',
    marginTop: 25,
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
});
