import { apiUrl, pigaiUrl, zhikeUrl } from './config';

const isDev = process.env.NODE_ENV !== 'production';

const api = isDev ? 'https://api.dev.smartstudy.com' : 'https://api.smartstudy.com';

export const getPractice = ['get', `${apiUrl}/toefl/practice`];
export const getPracticeByQuestion = ['get', `${apiUrl}/toefl/question/@id/practice`];
export const getExercise = ['get', `${apiUrl}/exercises/practice-detail`];
export const postAnswer = ['post', `${apiUrl}/answer`];
export const getMock = ['get', `${apiUrl}/practice-package/@mockId/practices`];
export const getUploadSignature = ['get', `${api}/file/upload/signature`];
export const postUpload = ['post', `${api}/file/upload/file`];
export const postCorrectionImage = ['post', 'https://api.smartstudy.com/api/v2/correction'];
export const postCorrection = ['post', `${apiUrl}/correction`];
export const postExercise = ['post', `${apiUrl}/exercise`];
export const getAnswer = ['get', `${apiUrl}/answers`];
export const getMockReport = ['get', `${apiUrl}/report/mock/detail`];
export const getReport = ['get', `${apiUrl}/report/@mode`];
export const postCorrect = ['post', `${apiUrl}/manual-pigai/submit`];
export const getCorrect = ['get', `${apiUrl}/manual-pigai/report`];
export const postComplete = ['post', `${apiUrl}/exercises/complete`];
export const getExerciseList = ['get', `${apiUrl}/exercises/list`];
export const getSpeakingMachinePigai = ['get', `${apiUrl}/machine-pigai/speaking/result/batch`];
export const getWritingMachinePigai = ['get', `${apiUrl}/machine-pigai/writing/result/batch`];
export const getMachinePigai = ['get', `${apiUrl}/machine-pigai/@subject/result`];
export const getAIPigai = ['get', `${apiUrl}/machine-pigai/@subject/result/v2`];
export const postDuration = ['post', `${apiUrl}/exercises/duration`];
export const getPigai = ['get', `${pigaiUrl}`];
export const getUserStatus = ['get', `${zhikeUrl}/api/user/status`];

// 收藏与笔记
export const addCollect = ['post', `${apiUrl}/collect/add`];
export const cancelCollect = ['post', `${apiUrl}/collect/cancel`];
export const getCollect = ['get', `${apiUrl}/collect/list`];
export const addNote = ['post', `${apiUrl}/notes/add`];
export const editNote = ['post', `${apiUrl}/notes/edit`];
export const delNote = ['post', `${apiUrl}/notes/delete`];
export const getNote = ['get', `${apiUrl}/notes/list`];
export const getSearchWord = ['get', `${apiUrl}/word/brief`];

// 机器批改 与外教批改
export const postManualPigai = ['post', `${apiUrl}/manual-pigai/submit-answer`];
export const postMachinePigai = ['post', `${apiUrl}/machine-pigai/@subject/submit`];
export const postAIPigai = ['post', `${apiUrl}/machine-pigai/@subject/submit/v2`];
export const getAnswerDetail = ['get', `${apiUrl}/answers`];
export const getAnswerOnly = ['get', `${apiUrl}/answers/only`];

// 动态获取站点logo
export const getLogo = ['get', `${apiUrl}/user/organization/logo`];

// 批改报告
export const postPractice = ['post', `${apiUrl}/machine-pigai/answer-practice`];
export const postPracticeAnalysis = ['post', `${apiUrl}/machine-pigai/answer-practice/analysis`]
