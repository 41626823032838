/* eslint-disable */
import React from 'react';
import Markdown from 'markdown-to-jsx';

const MyComponent = (props) => {
  return (
    <Markdown children={props.analysis} />
  );
};

export default MyComponent;
