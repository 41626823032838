/* eslint-disable */
import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'cookie';
import ReactEcharts from 'echarts-for-react';
import Notification from '../notification';
import AnswerAnalysis from './answer_analysis'
import { css } from 'aphrodite/no-important';
import { View } from '@zhike/ti-ui';
import { redirectToLoginPage } from 'utils/action';
import { postPractice, postPracticeAnalysis } from 'common/apis';
import { get } from 'lodash'


import styles from './styles';


class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    }
  }

  onChartClick = (params) => {
    if (params.componentIndex === 0) {
      return;
    }
    const { handleClick } = this.props;
    if (params && params.data) {
      handleClick(params.data.weakType);

      this.setState({
        currentIndex: params.dataIndex
      });

      // Add smooth scrolling to Practice section
      const practiceSection = document.getElementById('practice');
      if (practiceSection) {
        practiceSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  render() {
    const { list } = this.props
    const weakTypeCounts = list.reduce((acc, item) => {
      const { weak_type, weak_type_description } = item;
      const weak_type_chinese = item.weak_type_chinese || '其他错误';
      if (item.type === 'bad') {
        if (!acc[weak_type_chinese]) {
          acc[weak_type_chinese] = {
            value: 0,
            weakType: weak_type,
            weak_type_description: weak_type_description,
          };
        }
        (acc[weak_type_chinese].value)++;
      }
      return acc;
    }, {});

    // console.log(weakTypeCounts);


    const option = {
      // title: {
      //   text: [
      //     `{a|错误总数}`,
      //     `{b|${list.filter(x => x.type === 'bad').length}}个`
      //   ].join('\n'),
      //   left: 'center',
      //   top: 'center',
      //   // textVerticalAlign: 'auto',
      //   textStyle: {
      //     rich: {
      //       a: {
      //         color: '#fff',
      //         backgroundColor: '#17135d',
      //         padding: [20, 20],
      //         borderRadius: 100, // Make it circular
      //         lineHeight: 30,
      //         width: 100,
      //         height: 100,
      //         fontSize: 18,
      //       },
      //       b: {
      //         color: '#fff',
      //         backgroundColor: '#17135d',
      //         lineHeight: 30,
      //         fontSize: 18,
      //       }
      //     }
      //   }
      // },
      // tooltip: {
      //   trigger: 'item'
      // },
      legend: {
        show: false,
      },
      series: [
        {
          type: 'pie',
          selectedOffset: 0,
          selectedMode: false,
          hoverOffset: 0,
          radius: [0, '20%'],
          emphasis: {
            scale: false,
            disabled: true
          },
          label: {
            show: true,
            position: 'center',
            formatter: [
              '{a|错误总数}',
              '{b|' + list.filter(x => x.type === 'bad').length + '个}'
            ].join('\n'),
            rich: {
              a: {
                color: '#fff',
                padding: [5, 0],
                fontSize: 18,
                lineHeight: 30
              },
              b: {
                color: '#fff',
                padding: [0, 0],
                fontSize: 18,
                fontWeight: 'bold',
                lineHeight: 30
              }
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: list.filter(x => x.type === 'bad').length,
              itemStyle: {
                color: '#17135d',
              }, label: {
                show: true,
                color: '#fff',
              }
            },
          ],
          tooltip: false,
        },
        {
          name: '错误类型',
          type: 'pie',
          radius: ['45%', '60%'], // 设置环形图的内外半径
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: 'outside', // 固定显示在每个饼图旁
            formatter: (params) => {
              // 将描述分割为每20个字符一行，优先在空格处换行
              const splitDescription = (desc) => {
                if (!desc) {
                  return ''
                }
                const words = desc.split(' '); // 按空格分割单词
                let lines = [];
                let currentLine = '';

                words.forEach(word => {
                  if ((currentLine + word).length > 40) {
                    lines.push(currentLine.trim()); // 将当前行添加到行数组
                    currentLine = word + ' '; // 开始新的一行
                  } else {
                    currentLine += word + ' '; // 添加单词到当前行
                  }
                });

                if (currentLine) {
                  lines.push(currentLine.trim()); // 添加最后一行
                }

                return lines.join('\n'); // 用换行符连接
              };

              return [
                `{dot${params.dataIndex}|●}{width|${params.name}:} {perc|${params.percent}%}`,
                `{desc|${splitDescription(params.data.weakTypeDesc)}}\n\n\n`
              ].join('\n');
            },
            // borderColor: '#8C8D8E',
            // borderWidth: 1,
            rich: {
              // 动态生成不同颜色的点样式
              ...Array.from({ length: 8 }, (_, i) => ({
                [`dot${i}`]: {
                  color: ['#ffc100', '#ff9e00', '#ff6100', '#ff0000', '#f8369c', '#b440e9', '#5548e8', '#47b2f4'][i],
                  fontSize: 14,
                  width: 20,
                  align: 'left',
                  padding: [0, 0, 0, 0],
                },
              })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
              width: {
                fontFamily: 'serif',
                fontWeight: 700,
                color: 'black',
                align: 'left',
                padding: [0, 0, 0, 0],
                fontSize: 18,
              },
              perc: {
                color: 'black',
                align: 'left',
                fontSize: 18,
              },
              desc: {
                color: '#15104D',
                fontSize: 12,
                padding: [0, 0, 10, 0],
                align: 'left', // 确保左对齐
                width: 100, // 设置宽度限制
                // overflow: 'breakAll', // 允许文本换行
              }
            },
            color: '#000',
            fontSize: 14,
          },
          emphasis: {
            label: {
              show: true,
            }
          },
          labelLine: {
            show: true, // 显示连接线
            length: 90,
          },
          data: Object.entries(weakTypeCounts).map(([name, { value, weakType, weak_type_description }], index) => {
            return {
            value,
            name,
            weakType,
            weakTypeDesc: weak_type_description || '',
            selected: index === this.state.currentIndex // 设置第一个item为选中状态
          }}),
          itemStyle: {
            color: (params) => {
              const colors = ['#ffc100', '#ff9e00', '#ff6100', '#ff0000', '#f8369c', '#b440e9', '#5548e8', '#47b2f4'];
              return colors[params.dataIndex % colors.length];
            }
          }
        }
      ]
    }
    return (
      <ReactEcharts
        option={option}
        onEvents={{
          'click': this.onChartClick // 处理点击事件
        }}
        style={{ width: '100%', height: '1000px', transform: 'scale(.92)' }}
      />
    )
  }
}

class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weakType: get(props, ['practiceList', 0,'weak_type']),
      answers: {},
      selectedQuestion: null,
      loading: false,
      token: '',
      analysis: {},
      pigaiQuestionId: 0,
      notificationVisible: false,
      message: 'Good work',
      practiceList: props.practiceList,
    };
  }

  componentDidMount() {
    this.getToken();
  }

  getToken() {
    try {
      const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
      this.setState({ token: user.token })
    } catch (e) {
      redirectToLoginPage();
      return null;
    }
  }

  handleClick = (weakType) => {
    this.setState({
      weakType
    })
  }

  handleChange = (key, questionIndex, option, optionIndex, pigaiQuestionId) => {
    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [`${key}_${questionIndex}`]: {
          option,
          optionIndex,
          pigaiQuestionId,
        }
      }
    }));
  };

  handleSubmit = async ({ weakType, pigaiQuestionId, questionIndex, answerId }) => {
    const answerKey = `${pigaiQuestionId}_${questionIndex}`;
    const selectedAnswer = this.state.answers[answerKey];
    const { token } = this.state

    if (!selectedAnswer) {
      // 如果用户没有选择答案，可以显示提示
      alert('Please select an answer first');
      return;
    }

    const res = await axios({
      url: postPractice[1],
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        From: 1,
      },
      params: {
        answerId,
        token,
        tranResult: 2
      },
      data: {
        answerId,
        pigaiAnswer: selectedAnswer.optionIndex,
        pigaiQuestionId: selectedAnswer.pigaiQuestionId,
      }
    })

    // TODO: 处理提交逻辑
    if (res.data && res.data.code === 0) {
      this.updateUserAnswer(weakType, selectedAnswer.pigaiQuestionId, selectedAnswer.optionIndex, res.data.data.isRight) 
      if (res.data.data.isRight) {
        this.setState({
          notificationVisible: true,
          message: 'Good work'
        })
      } else {
        this.setState({
          notificationVisible: true,
          message: 'Sorry, incorrect'
        })
      }
    } else {
      console.log('request submit error')
    }
  };

  updateUserAnswer = (weakType, questionId, newAnswer, isRight) => {
    this.setState(prevState => {
      // Access practiceList
      const updatedPracticeList = prevState.practiceList.map(weak => {
        if (weak.weak_type === weakType) {
          // Find and update the target question
          const updatedQuestions = weak.questions.map(question => {
            if (question.id === questionId) {
              return {
                ...question,
                userAnswer: newAnswer, // Update the userAnswer
                isRight, // Update the userAnswer
              };
            }
            return question; // Return unchanged question
          });
  
          return {
            ...weak,
            questions: updatedQuestions, // Replace the questions array
          };
        }
        return weak; // Return unchanged weak_type
      });
  
      return {
        ...prevState, 
        practiceList: updatedPracticeList, // Replace the practiceList
      };
    });
  };
  


  closeNotification = () => {
    this.setState({
      notificationVisible: false,
    });
  };

  handleAnswerAnalysis = async ({ pigaiQuestionId, answerId }) => {
    this.setState({ pigaiQuestionId, loading: true })
    const { token } = this.state;
    const res = await axios({
      url: postPracticeAnalysis[1],
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        From: 1,
      },
      params: {
        answerId,
        token,
        tranResult: 2
      },
      data: {
        answerId,
        pigaiQuestionId: Number(pigaiQuestionId),
      }
    });

    if (res.data && res.data.code === 0) {
      if (res.data.data.analysis) {
        this.setState(prevState => ({
          ...prevState.analysis,
          loading: false,
          analysis: {
            [`${pigaiQuestionId}`]: {
              analysis: res.data.data.analysis
            }
          }
        }));
      }
    } else {
      console.log('request analysis error');
    }
  }

  render() {
    const { weakType, practiceList, loading, pigaiQuestionId } = this.state;
    const { list, answerId } = this.props;

    return (
      <View className={styles.chartContainer}>
        <Chart list={list} weakType={weakType} handleClick={this.handleClick} />
        <h3 id="practice">Practice</h3>
        {Object.entries(practiceList).map(([key, practice], index) =>
          practice.weak_type === weakType && practice.questions.map((questionItem, questionIndex) => {

            return (
              <View
                key={`${key}_${questionIndex}`}
                className={styles.questionContainer}
              >
                <View className={styles.questionText}>
                  {questionIndex + 1}. {questionItem.question}
                </View>

                <View className={styles.optionsContainer}>
                  {questionItem.options.map((option, optionIndex) => (
                    <label
                      key={`${key}_${questionIndex}_${optionIndex}`}
                      className={styles.label}
                    >
                      <input
                        type="radio"
                        disabled={questionItem.isRight === true}
                        name={`question_${key}_${questionIndex}`}
                        value={optionIndex}
                        defaultChecked={questionItem.isRight === true ? optionIndex === questionItem.answer :
                          (questionItem.isRight === false ? 
                           optionIndex !== questionItem.answer : undefined)}
                        // checked={currentAnswer && currentAnswer.optionIndex === optionIndex}
                        // checked={questionItem.isRight}
                        onChange={() => this.handleChange(key, questionIndex, option, optionIndex, questionItem.id)}
                      />
                      {String.fromCharCode(optionIndex + 65)}. {option}
                    </label>
                  ))}
                  <span
                    className={css(questionItem.isRight !== null ? (questionItem.isRight ? styles.correctMessage : styles.incorrectMessage) : styles.default)}
                  >
                    { questionItem.isRight !== null ? (questionItem.isRight ? '回答正确' : '回答错误') : '' }
                  </span>
                </View>

                <View className={styles.btnWrapper}>
                  {questionItem.isRight !== true &&
                    <View
                      className={styles.submitBtn}
                      onClick={() => this.handleSubmit({
                        weakType: practice.weak_type,
                        answerId,
                        pigaiQuestionId: key,
                        questionIndex
                      })}
                    >
                      提交
                    </View>
                  }

                  <div
                    className={css(
                      styles.analysisBtn,
                      this.state.analysis[questionItem.id] && styles.analysisButtonDisabled
                    )}
                    onClick={() => {
                      if (!this.state.analysis[questionItem.id]) {
                        this.handleAnswerAnalysis({
                          pigaiQuestionId: questionItem.id,
                          answerId
                        });
                      }
                    }}
                  >
                    答案解析
                  </div>
                </View>

                <View className={styles.answerAnalysisContainer}>
                  {pigaiQuestionId === questionItem.id && loading && <View className={styles.loadingAnimation}>正在加载中...</View>}
                  {/* {this.state.analysis && this.state.analysis[questionItem.id] && this.state.analysis[questionItem.id].analysis} */}
                  <AnswerAnalysis analysis={this.state.analysis && this.state.analysis[questionItem.id] && this.state.analysis[questionItem.id].analysis} />
                </View>
              </View>
            );
          })
        )}
        {this.state.notificationVisible && (
          <Notification message={this.state.message} duration={3000} onClose={this.closeNotification} />
        )}
      </View>
    );
  }
}

export default Practice;
