import React from 'react';
import { View } from '@zhike/ti-ui';
import styles from './notification';

const Notification = ({ message, duration = 3000, onClose }) => {
  React.useEffect(() => {
    // Auto-close the notification after the specified duration
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [duration, onClose]);

  return (
    <View className={styles.notification}>
      <View className={styles.message}>{message}</View>
      <View className={styles.subtext}>{duration / 1000} 秒钟后自动消失</View>
    </View>
  );
};

export default Notification;
