/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cookie from 'cookie';
import { getAIPigai } from 'common/apis';
import { redirectToLoginPage } from 'utils/action';
import { View, Image } from '@zhike/ti-ui';
import styles from './styles';

export default class ScanAnimation extends React.Component {
  static propTypes = {
    onPoll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      scanLinePosition: 0,
      isAnimating: true,
      token: '',
      aiPigaiStatus: '',
      answerText: '',
    };
    const { match } = props;
    const { subjectId } = match.params;
    this.animationDuration = parseInt(subjectId, 10) === 8 ? 26000 : 16000; // 16 seconds
    this.pollInterval = 1000; // 1 second
  }

  componentDidMount() {
    // this.getToken();
    // this.fetchCorrectData()
    this.startAnimation();
    this.startPolling();   
  }

  componentWillUnmount() {
    this.stopAnimation();
    this.stopPolling();
  }

  async fetchCorrectData(token, subject, answerId) {
    const response = await axios({
      url: `${getAIPigai[1].replace(/@subject/, subject)}?answerId=${answerId}&token=${token}&tranResult=2`,
      headers: {
        From: 1,
      },
      method: 'GET',
    });

    this.setState({
      answerText: response.data.data.answerText,
      aiPigaiStatus: response.data.data.aiPigaiStatus,
    })

    if (response.data.code === 'BadGateway') {
      redirectToLoginPage();
      return null;
    }
    return response;
  }

  getToken() {
    try {
      const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
      this.setState({ token: user.token })
    } catch (e) {
      console.log(e, 'token');
      redirectToLoginPage();
      return null;
    }
  }

  

  startAnimation = () => {
    const startTime = Date.now();
    
    const animate = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / this.animationDuration, 1);
      
      const maxProgress = this.state.aiPigaiStatus === 'Marked' ? 100 : 99;
      const currentPosition = progress * maxProgress;
      
      this.setState({
        scanLinePosition: currentPosition
      });

      if (this.state.aiPigaiStatus !== 'Marked' || currentPosition < 100) {
        this.animationFrame = requestAnimationFrame(animate);
      } else {
        this.setState({ isAnimating: false }, () => {
          const { match, history, location } = this.props;
          const { subjectId, answerId } = match.params;
          const { token } = this.state;
          const title = new URLSearchParams(location.search).get('title')

          // 直接使用 history.replace 进行跳转
          const url = `/machine-correctv2/${subjectId}/${answerId}?title=${title}&token=${token}`;
          history.replace(url)
        });
      }
    };

    this.animationFrame = requestAnimationFrame(animate);
  };

  stopAnimation = () => {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  };

  startPolling = () => {
    const { token } = this.state
    const { match } = this.props;
    const { subjectId, answerId } = match.params;
    const subject = parseInt(subjectId, 10) === 8 ? 'speaking' : 'writing';
    
    this.pollTimer = setInterval(() => {
      if (this.state.isAnimating) {
        this.fetchCorrectData(token, subject, answerId);
      } else {
        this.stopPolling();
      }
    }, this.pollInterval);
  };

  stopPolling = () => {
    if (this.pollTimer) {
      clearInterval(this.pollTimer);
    }
  };

  render() {
    const { scanLinePosition, answerText } = this.state;
    const isDemo = new URLSearchParams(this.props.location.search).get('gif')

    return (
      <View className={styles.scanContainer}>
        {/* <View className={styles.zw}>{answerText}</View> */}
        <View
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Image
              src='https://media8.smartstudy.com/apollon/materials/ai_loading_6.gif'
              style={{
                width: '80%',
                margin: '0 auto',
              }}
            />
          {/* {isDemo == 1 ?
            <Image
              src='https://media8.smartstudy.com/ai/tmp/ai_loading_4.gif'
              style={{
                width: '80%',
                margin: '0 auto',
              }}
            /> :
            <Image
              src='https://media8.smartstudy.com/ai/tmp/ai_loading_5.gif'
              style={{
                width: '80%',
                margin: '0 auto',
              }}
            />
          } */}
          {/* <Image
            src={LoadingGif}
            style={{
              width: '80%',
              margin: '0 auto',
            }}
          /> */}
          <View
            style={{
              position: 'relative', 
              width: '90%',
              height: '8px',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              margin: '20px auto 0',
              boxShadow: '0 0 10px rgba(0, 150, 255, 0.3)',
            }}
          >
            <View
              style={{
                width: `${scanLinePosition}%`,
                height: '100%',
                background: 'linear-gradient(90deg, rgba(0,150,255,0.8) 0%, rgba(0,219,255,0.8) 100%)',
                borderRadius: '4px',
                transition: 'width 16ms linear',
                position: 'relative',
                boxShadow: '0 0 15px rgba(0, 150, 255, 0.5)',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '20px',
                  height: '100%',
                  background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 100%)',
                  animation: 'shine 1s infinite'
                }
              }}
            >
              <View
                style={{
                  position: 'absolute',
                  top: '-30px',
                  right: '-25px',
                  color: '#fff',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textShadow: '0 0 10px rgba(0, 150, 255, 0.8)',
                }}
              >
                {`${Math.round(scanLinePosition)}%`}
              </View>
            </View>
          </View>
          <View
            style={{
              color: '#fff',
              fontSize: '16px',
              marginTop: '20px'
            }}
          >
            批改中,请耐心等待...
          </View>
        </View>
      </View>
    );
  }
}

