/* eslint-disable */
import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Helvetica Neue,sans-serif,Arial',
    height: '100vh',
    // overflow: 'hidden',
  },
  answerText: {
    marginRight: '48px',
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  answerTextContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    overflow: 'hidden',
    // border: '1px solid rgba(0, 0, 0, 0.05)',
  },

  num: {
    display: 'inline-block',
    padding: '2px 8px',
    backgroundColor: '#217af5',
    borderRadius: '12px',
    fontSize: '14px',
    color: '#fff',
    verticalAlign: 'middle',
    marginLeft: '8px',
  },

  result: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid rgba(0, 0, 0, 0.05)',
  },

  resultItem: {
    position: 'relative',
    marginTop: '45px',
    width: '520px',
    padding: '20px 24px',
    borderRadius: '12px',
    color: '#0e101a',
    fontSize: '14px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    border: '1px solid #eaecef',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
   
    ':hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
    }
  },

  resultItemSelected: {
    position: 'relative',
    marginTop: '45px',
    width: '520px',
    padding: '20px 24px',
    borderRadius: '12px',
    color: '#0e101a',
    cursor: 'pointer',
    backgroundColor: '#fff',
    fontSize: '14px',
    transition: 'all 0.3s ease',
    border: '1px solid rgb(247, 178, 82)',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
    transform: 'translateY(-2px)',
    animationName:  {
      '0%': {
        transform: 'translateY(0)',
        boxShadow: '0 0 0 rgba(33,122,245,0)',
      },
      '50%': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 24px rgba(33,122,245,0.4)',
      },
      '100%': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(33,122,245,0.2)',
    },
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease',
  },

    '& .text': {
      backgroundColor: '#217af5',
    },

    '& .titleContent': {
      color: '#217af5',
    }
  },

  resultItemAdviceSelected: {
    marginTop: '75px',
    width: '520px',
    padding: '12px 12px 12px 16px',
    borderRadius: '8px',
    fontColor: '#6b7280',
    cursor: 'pointer',
    backgroundColor: '#fdf7ea',
    fontSize: '14.875px',
    transition: 'fontSize 0.3s ease',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },

  typeBox: {
    display: 'flex',
    position: 'relative',
    top: '-68px',
    left: '-24px',
  },

  text: {
    position: 'absolute',
    left: '20px',
    top: '27px',
    zIndex: 10,
    padding: '0 10px',
    // width: '106px',
    height: '40px',
    lineHeight: '40px',
    color: '#fff',
    fontSize: '15px',
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: '20px',
    transition: 'all 0.3s ease',
  },

  title: {
    // marginTop: '8px',
    paddingLeft: '12px',
    // marginBottom: '12px',
    fontSize: '14px',
  },

  titleContent: {
    color: '#4a5568',
    lineHeight: '1.6',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    padding: '4px 0',
  },

  sidebar: {
    marginLeft: '30px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  sidebarContent: {
    marginTop: '24px',
  },

  progressbar: {
    margin: '8px 0 16px 0',
    width: '100%',
    height: '3px',
    backgroundColor: '#ff99ab',
  },

  activebar: {
    height: '3px',
    backgroundColor: '#ea1537',
  },

  types: {
    fontWeight: 500,
    fontSize: '14.875px',
    color: '#0e101a',
  },

  questionText: {
    whiteSpace: 'pre-wrap',
    lineHeight: '2.5',
    marginBottom: '10px',
  },

  chartContainer: {
    // backgroundColor: '#f6f5f7',
    width: '100%',
  },

  resultContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '40px',
    gap: '24px',
  },

  resultBoxItem: {
    width: '280px',
    borderRadius: '16px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    transition: 'all 0.3s ease',

    ':hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
    }
  },

  itemTitle: {
    padding: '0 24px',
    backgroundColor: '#217af5',
    height: '50px',
    lineHeight: '50px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
  },

  indexText: {
    fontSize: '32px',
    fontWeight: 600,
    color: '#0e101a',
  },

  subText: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row',
    color: '#4a5568',
    fontSize: '14px',
    lineHeight: '1.6',
  },

  mainBox: {
    padding: '16px',
  },

  beizhu: {
    fontSize: '13px',
    color: '#718096',
    marginTop: '12px',
    lineHeight: '1.5',
  },

  label: {
    display: 'block',
    position: 'relative',
    paddingLeft: '40px',
    marginBottom: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    color: '#4a5568',
    fontSize: '15px',

    ':hover': {
      color: '#217af5',
    },

    '& input[type="radio"]': {
      position: 'absolute',
      left: '12px',
      top: '4px',
      width: '16px',
      height: '16px',
      cursor: 'pointer',
    }
  },

  submitBtn: {
    marginRight: '12px',
    width: '120px',
    height: '36px',
    lineHeight: '36px',
    textAlign: 'center',
    backgroundColor: '#217af5',
    color: '#fff',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '15px',
    fontWeight: 500,
    
    ':hover': {
      backgroundColor: '#1b6ad9',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 12px rgba(33, 122, 245, 0.2)',
    },

    ':active': {
      transform: 'translateY(0)',
      boxShadow: 'none',
    }
  },

  scrollContainer: {
    flex: 1,
    paddingRight: '9px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#c1c1c1',
      borderRadius: '3px',
      ':hover': {
        background: '#a8a8a8',
      }
    }
  },

  textTitle: {
    padding: '20px 24px',
    fontSize: '18px',
    fontWeight: 500,
    color: '#0e101a',
    borderBottom: '1px solid #eaecef',
  },

  textContent: {
    flex: 1,
    padding: '24px',
    color: '#0e101a',
    fontWeight: 300,
    lineHeight: '2',
    fontSize: '16px',
    overflowY: 'auto',
    
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#c1c1c1',
      borderRadius: '3px',
      ':hover': {
        background: '#a8a8a8',
      }
    },

    '& span': {
      transition: 'all 0.3s ease',
      ':hover': {
        opacity: 0.8,
      }
    }
  },

  questionContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '24px',
    marginBottom: '20px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
    border: '1px solid #eaecef',
    transition: 'all 0.3s ease',
  },

  questionText: {
    whiteSpace: 'pre-wrap',
    lineHeight: '1.8',
    marginBottom: '20px',
    fontSize: '16px',
    color: '#0e101a',
    fontWeight: 500,
  },

  optionsContainer: {
    marginBottom: '20px',
  },

  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  analysisBtn: {
    width: '120px',
    height: '36px',
    lineHeight: '36px',
    textAlign: 'center',
    backgroundColor: 'white',
    border: '1px solid #3367d6',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '15px',
    fontWeight: 500,
  },

  answerAnalysisContainer: {
    marginTop: '12px',
    fontSize: '14px',
  },

  loadingAnimation: {
    fontSize: '12px',
    color: '#217af5',
    animationName: {
      '0%': {
        opacity: 0.5,
      },
      '100%': {
        opacity: 1,
      }
    },
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'alternate'
  },

  correct: {
    color: 'green',
  },
  
  incorrect: {
    color: 'red',
  },
  
  default: {
    color: '#4a5568',
  },

  questionCorrect: {
    borderColor: '#52f760',
    backgroundColor: 'rgba(82, 247, 96, 0.02)',
  },

  questionIncorrect: {
    borderColor: '#f7b252',
    backgroundColor: 'rgba(247, 178, 82, 0.02)',
  },

  labelSelected: {
    color: '#217af5',
    fontWeight: 500,
  },

  labelDisabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },

  resultMessage: {
    marginTop: '16px',
    padding: '12px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: 500,
  },

  correctMessage: {
    marginTop: '16px',
    // backgroundColor: 'rgba(82, 247, 96, 0.1)',
    color: '#15c39a',
  },

  incorrectMessage: {
    marginTop: '16px',
    // backgroundColor: 'rgba(247, 178, 82, 0.1)',
    color: '#f7b252',
  },

  analysisButtonDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
});
