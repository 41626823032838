/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import ReactEcharts from 'echarts-for-react';
import { View } from '@zhike/ti-ui';
import styles from './styles';
import RadarModal from '../../report/mock/components/Radar';

moment.locale('zh-cn');

// 报告
export default class Header extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
    subjectId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { subjectId } = this.props;
    global.document.title = `${subjectId === '8' ? '口语批改' : '写作批改'} - 智课`;
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  generateTop = () => {
    const { aiPigaiSubmitTime } = this.props.correctData;
    const search = global.location.search; // eslint-disable-line
    const title = decodeURIComponent(search.match(new RegExp('[?#&]title=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let { aiPigaiScore } = this.props.correctData;
    aiPigaiScore = this.handleScore(aiPigaiScore);

    let style = {
      backgroundImage: `url(${require('./assets/grade_c.png')})`,
      color: '#DCDCDC',
      textShadow: '0 2px 4px #505050',
    };
    if (aiPigaiScore >= 21) {
      style = {
        backgroundImage: `url(${require('./assets/grade_a.png')})`,
        color: '#F5D5A8',
        textShadow: '0 2px 4px #9A7136',
      };
    }
    if (aiPigaiScore <= 20 && aiPigaiScore >= 11) {
      style = {
        backgroundImage: `url(${require('./assets/grade_b.png')})`,
        color: '#E3EDEC',
        textShadow: '0 2px 4px #4F6788',
      };
    }
    return (
      <View className={styles.top}>
        <View className={styles.title}>{title}</View>
        <View className={styles.time}>{moment(aiPigaiSubmitTime).format('YYYY.MM.DD')}</View>
        <View className={styles.score} style={style}>{aiPigaiScore} </View>
      </View>
    );
  }

  generateComment(title, content, color) {
    return (
      <View className={styles.comment}>
        <View className={styles.commentTitle} style={{ backgroundColor: color }}>{title}</View>
        <View>{content}</View>
      </View>
    );
  }

  generateCommentHalf(title, content, color) {
    return (
      <View className={styles.commentHalf}>
        <View className={styles.commentTitle} style={{ backgroundColor: color }}>{title}</View>
        <View>{content}</View>
      </View>
    );
  }

  generateContent = () => {
    const { subjectId, correctData } = this.props;
    const { aiPigaiRadar, aiPigaiResult } = correctData;
    const comment = _.get(aiPigaiResult, ['all', 'comment'], '')
    const chineseComment = _.get(aiPigaiResult, ['all', 'chinese_comment'], '')

    let option = {};
    if (subjectId === '7') {
      const { grammaticalRangeAndAccuracy, lexicalResources, coherenceCohesion, taskResponse } = aiPigaiRadar;
      option = {
        radar: {
          shape: 'circle',
          silent: true,
          splitNumber: 10,
          splitArea: {
            areaStyle: { color: ['#fff'] },
          },
          startAngle: 45,
          name: {
            color: '#505050',
          },
          indicator: [
            { name: `Grammatical Range and Accuracy ${this.handleScore(grammaticalRangeAndAccuracy, 9)}`, max: 9 },
            { name: `Lexical Resources ${this.handleScore(lexicalResources, 9)}`, max: 9 },
            { name: `Cohrence &  Cohesion ${this.handleScore(coherenceCohesion, 9)}`, max: 9 },
            { name: `Task Response ${this.handleScore(taskResponse, 9)}`, max: 9 },
          ],
        },
        series: [{
          type: 'radar',
          data: [{
            value: [
              this.handleScore(grammaticalRangeAndAccuracy, 9),
              this.handleScore(lexicalResources, 9),
              this.handleScore(coherenceCohesion, 9),
              this.handleScore(taskResponse, 9),
            ],
            name: '预算分配（Allocated Budget）',
            areaStyle: {
              normal: {
                opacity: 0.5,
                color: '#3399ff',
              },
            },
            symbol: 'none',
            lineStyle: {
              normal: {
                opacity: 0.5,
                color: '#3399ff',
              },
            },
          }],
        }],
      };
    } else if (subjectId === '8') {
      option = {
        radar: {
          shape: 'circle',
          silent: true,
          splitNumber: 10,
          splitArea: {
            areaStyle: { color: ['#fff'] },
          },
          startAngle: 45,
          name: {
            color: '#505050',
          },
          indicator: [
            { name: `Pronunciation ${aiPigaiResult.pronunciation.score}`, max: 9 },
            { name: `Grammatical Range and Accuracy ${aiPigaiResult.grammaticalRangeAndAccuracy.score}`, max: 9 },
            { name: `Lexical Resources ${aiPigaiResult.lexicalResources.score}`, max: 9 },
            { name: `Fluency & Coherence ${aiPigaiResult.fluencyCoherence.score}`, max: 9 },
          ],
        },
        series: [{
          type: 'radar',
          data: [{
            value: [
              aiPigaiResult.pronunciation.score,
              aiPigaiResult.grammaticalRangeAndAccuracy.score,
              aiPigaiResult.lexicalResources.score,
              aiPigaiResult.fluencyCoherence.score,
            ],
            name: '预算分配（Allocated Budget）',
            areaStyle: {
              normal: {
                opacity: 0.5,
                color: '#3399ff',
              },
            },
            symbol: 'none',
            lineStyle: {
              normal: {
                opacity: 0.5,
                color: '#3399ff',
              },
            },
          }],
        }],
      };
    }

    return (
      <View className={styles.commentBox}>
        {this.generateCommentHalf('综合评价', <View className={styles.p} >• {comment} <br /> {chineseComment}</View>, '#FC6238')}
        {this.generateCommentHalf('雷达图', <RadarModal
          data={option.series[0].data[0].value}
          indicator={option.radar.indicator}
          max={9}
          styles={{ height: '200px', width: '500px', margin: '20px auto' }}
        />, '#3399ff')}
      </View>
    );
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        {this.generateTop()}
        {this.generateContent()}
      </View>
    );
  }
}
