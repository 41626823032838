/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import { get } from 'lodash';
import styles from './styles';

class Result extends Component {
  render() {
    const { statistics, isSpeaking } = this.props
    const {
      words: wordCount = '未知',
      referenceWord = '未知',
      duration = '未知',
      referenceDuration = '未知',
      errorCount = '未知',
      errorTypeCount = '未知',
    } = statistics || {};

    return (
      <View className={styles.resultContainer}>
        <View className={styles.resultBoxItem}>
          <View className={styles.itemTitle}>字数 (个)</View>
          <View className={styles.mainBox}>
            <View className={styles.indexText}>{wordCount}</View>
            <View className={styles.subText}>参考字数<span> </span> <span style={{ color: '#9be298', marginLeft: '6px', fontWeight: 500 }}> {referenceWord}</span></View>
            <View className={styles.beizhu}>备注：{isSpeaking ? '口语回答': '作文'}字数没有官方标准，但原则是能表达自己的意思即可</View>
          </View>
        </View>

        <View className={styles.resultBoxItem}>
          <View className={styles.itemTitle}>用时 (分钟)</View>
          <View className={styles.mainBox}>
          <View className={styles.indexText}>{duration}</View>
            <View className={styles.subText}>参考时间<span> </span> <span style={{ color: '#9be298', marginLeft: '6px', fontWeight: 500 }}> {referenceDuration}</span></View>
            <View className={styles.beizhu}>备注：想提高写作速度，最重要的是平时要多多练习，另外就是要保证脑中有物，才能从容应对</View>
          </View>
        </View>

        <View className={styles.resultBoxItem}>
          <View className={styles.itemTitle}>错误数 (个)</View>
          <View className={styles.mainBox}>
            <View className={styles.indexText}>{errorCount}</View>
            {/* <View className={styles.subText}><span>平均值 </span> <span style={{ color: '#9be298', marginLeft: '6px', fontWeight: 500 }}> 14</span></View> */}
            <View className={styles.subText} style={{ opacity: 0 }}><span>平均值 </span> <span style={{ color: '#9be298', marginLeft: '6px', fontWeight: 500 }}> 14</span></View>
            <View className={styles.beizhu}>备注：此处的数字为该文章中待改善的句子数</View>
          </View>
        </View>

        <View className={styles.resultBoxItem}>
          <View className={styles.itemTitle}>错误类型 (个)</View>
          <View className={styles.mainBox}>
            <View className={styles.indexText}>{errorTypeCount}</View>
            {/* <View className={styles.subText}><span>平均值 </span> <span style={{ color: '#9be298', marginLeft: '6px', fontWeight: 500 }}> 7</span></View> */}
            <View className={styles.subText} style={{ opacity: 0 }}><span>平均值 </span> <span style={{ color: '#9be298', marginLeft: '6px', fontWeight: 500 }}> 7</span></View>
            <View className={styles.beizhu}>备注：请你多多关注经常错误的类型并通过针对性的练习环节来各个击破，相信你一定可以做的更好</View>
          </View>
        </View>
      </View>
    )
  }
}

export default Result;
